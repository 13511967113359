export const Companies = [
  {
    _id:"64b92a1f14fd6b95f5bfd46d",
    name:"machinehack",
    slug: ["genpact-sky-dive-global-campus-academy-baseline",],
  },
  {
    _id:
      process.env.REACT_APP_ENV_TYPE === "production"
        ? "64df3fc42ba6b9fd3f786966"
        : "64c2012eb0ba118283d6b799",
    name:
      process.env.REACT_APP_ENV_TYPE === "production"
        ? "Genpact"
        : "My company",
    slug: [
      "genpact-sky-dive-global-campus-academy-baseline",
      "genpact-sky-dive-global-campus-academy-baseline-batch-2",
    ],
  },
  {
    _id:
      process.env.REACT_APP_ENV_TYPE === "production"
        ? "65276920d5d09f1578aa3b22"
        : "651f7e9d36ee3eb9f2346f40",
    name: "EXL",
    slug: ["mlo-ps","generative-ai-and-ll-ms"],
  },
  {
    _id:
      process.env.REACT_APP_ENV_TYPE === "production"
        ? "66bf0954d4803f1f47c07981"
        : "651f7e9d36ee3eb9f2346f40",
    name: "ADaSci",
    slug: ["mlo-ps","generative-ai-and-ll-ms"],
  },
  {
    _id:
      process.env.REACT_APP_ENV_TYPE === "production"
        ? "6733147b20934be256d5d3a9"
        : "6733147b20934be256d5d3a9",
    name: "Bain",
    slug: ["mlo-ps","generative-ai-and-ll-ms"],
  },
  {
    _id:
      process.env.REACT_APP_ENV_TYPE === "production"
        ? "67359912d2d21861ef903b4e"
        : "67359912d2d21861ef903b4e",
    name: "Honeywell",
    slug: ["generative-ai-llm-training-program"],
  },
  {
    _id:"6789f9186265ef2241eebb55",
    name:"GPTW",
    slug:["gptw-predictive-modeling-and-text-analytics-mastery-program"]
  }
];

export const blockCoursesFromMachinehack = [
  "64a7dcb9099f397c780f8a89",
  "64a26d2777c4b83508c937f7",
];

export const genpactCourses = [
  "6480156bc9c33e75cd68044d",
  "6516538c2d79980012ef388f",
  "64fa97a108bd9e0012d7fd50",
  "64f800b8b9a5fd0019671d37",
  "64f5b0cc1a13360012b925a0",
  "64f56a4cb9a5fd001964f24c",
  "64f566e67ad1dc0019a07b04",
  "64eeeca0618988001163bded",
  "64ed71316570d01f3ef85cd3",
  "64e84afbc90600af7eeb9c1a",
];

export const EXLCourses = ["62c2d18bb4ec9a81afe417b0"];
